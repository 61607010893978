@import './variables';
@import './ModalCommon.scss';

.user-notification-modal {
    @extend .common-modal;

    .wrapper {

    }

    .ant-tabs-tab {
        text-align: left !important;
        padding-left: 0px !important;
    }

    .ant-tabs-nav-wrap {
        padding-left: 0px;
    }

    .ant-modal-body {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }


}

.module-notification-modal-title {
    display: flex;
    justify-content: space-between;
    .is-good-icon-wrapper {
        display:flex;
        justify-content: center;
    }

}

.meta-flex {
    display: flex;
    justify-content: space-between;
}