@import '_variables.scss';

.public-DraftStyleDefault-ol:before {
  display: none;
}



.notification-preview {
  //previewにのみ適用されるものとしてimportant推奨
  ul,ol {
    list-style: decimal !important;
    list-style-position: inside !important;
  }

}
.notification-preview-download {
  margin-top: $gutter;
}